/* eslint-disable react/destructuring-assignment */
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { ReactSVG } from 'react-svg';
import { isString } from 'lodash';
import cx from 'classnames';

import * as selectors from 'app/selectors/customer';

import TitleWithLine from 'app/components/common/Typography/TitleWithLine';
import { Button } from 'app/components/common/Button';

import ReferralSourcesModal from 'app/components/customer/dashboard/ReferralSourcesModal';
import AdkDiscontinuationModal from 'app/components/customer/dashboard/AdkDiscontinuationModal';
import BbRelaunchModal from 'app/components/customer/dashboard/BbRelaunchModal';

import ProgressZero from 'images/progress-tracker/Progress0.svg';
import Progress25 from 'images/progress-tracker/Progress25.svg';
import Progress50 from 'images/progress-tracker/Progress50.svg';
import Progress75 from 'images/progress-tracker/Progress75.svg';
import Progress100 from 'images/progress-tracker/Progress100.svg';
import IconLock from 'images/progress-tracker/lock.svg';
import IconHamburger from 'images/progress-tracker/hamburger.svg';
import CircleActiveIcon from 'images/progress-tracker/dot.svg';
import CirclePassed from 'images/progress-tracker/dot-passed.svg';
import ArrowDown from 'images/progress-tracker/arrow-down.svg';

import { AvailableProducts } from 'app/constants/Products';
import { ProductContext } from '../Product';
import { formatDate } from 'app/components/admin/UserPage/utils';

import './css/Header.scss';
import protocolNames from 'app/utils/protocolNames';

const CircleActive = <ReactSVG src={CircleActiveIcon} />;

interface IWizardStep {
  icon: string | ReactNode;
  status: string;
}

const WizardItem: FC<{
  className?: cx.Argument;
  titleClassName?: cx.Argument;
  icon: string | ReactNode;
  status: string;
  withoutLine?: boolean;
}> = ({ className, icon, titleClassName, status, children, withoutLine = false }) => (
  <div className={cx('progress_tracker__wizard__item', className)}>
    <div className="progress_tracker__wizard__flex">
      {isString(icon) ? <img src={icon} /> : icon}
      {!withoutLine && <div className="progress_tracker__wizard__item__line" />}
    </div>
    <p className={cx('progress_tracker__wizard__title', titleClassName)}>{children}</p>
    <p className="progress_tracker__wizard__status">{status}</p>
  </div>
);
const TreatmentItem: FC<{
  titleClassName?: cx.Argument;
  icon: ReactNode;
  status: string;
  withoutLine?: boolean;
}> = ({ icon, titleClassName, status, children, withoutLine = false }) => (
  <div className={cx('modal_flex', convertStatusToCSSClass(status))}>
    <div>{isString(icon) ? <img src={icon} /> : icon}</div>
    <div className={cx('progress_tracker__wizard__title', titleClassName)}>{children}</div>
    <div className="progress_tracker__wizard__status">{status}</div>
    {!withoutLine && <img src={ArrowDown} />}
  </div>
);
const NUMBER_OF_STEPS = 4;
const imageMap = {
  0: ProgressZero,
  25: Progress25,
  50: Progress50,
  75: Progress75,
  100: Progress100,
};

const convertStatusToCSSClass = (status) => status.toLowerCase().replace(/ /g, '-').split(':')[0];

const calculatePercentage = (completed: boolean[], isOnboarding: boolean) => {
  const numberOfPassedSteps = completed.reduce((acc, value) => (value ? acc + 1 : acc), 0);

  const passedPercentage = (numberOfPassedSteps / NUMBER_OF_STEPS) * 100;
  if (!isOnboarding && passedPercentage > 0) {
    return passedPercentage + 25;
  }

  return passedPercentage;
};

const portalRegistrationState = (clinicianQuestionsCompletedAt, patientPortalRegisteredAt): IWizardStep => {
  let portalRegistrationStatus = 'Not Started';
  let portalRegistrationIcon = IconLock;
  if (clinicianQuestionsCompletedAt) {
    portalRegistrationStatus = 'In Progress';
    portalRegistrationIcon = CircleActive;
  }
  if (patientPortalRegisteredAt) {
    portalRegistrationStatus = `Completed on: ${patientPortalRegisteredAt}`;
    portalRegistrationIcon = CirclePassed;
  }

  return { status: portalRegistrationStatus, icon: portalRegistrationIcon };
};

const baselineLabResultsState = (clinicianQuestionsCompletedAt, labOrderResults) => {
  let baselineLabResultsStatus = 'Not Started';
  let baselineLabResultsIcon = IconLock;
  if (clinicianQuestionsCompletedAt) {
    baselineLabResultsStatus = 'In Progress';
    baselineLabResultsIcon = CircleActive;
  }
  if (labOrderResults.length > 0) {
    baselineLabResultsStatus = `Completed on: ${labOrderResults[0].date}`;
    baselineLabResultsIcon = CirclePassed;
  }

  return { status: baselineLabResultsStatus, icon: baselineLabResultsIcon };
};

const formattedDate = (date) => formatDate(date, 'MMM D, YYYY');

const ProgressTrackerHeader = () => {
  const productCtx = useContext(ProductContext);
  const customer = useAppSelector(selectors.selectCustomer);
  const flags = useAppSelector(selectors.selectCustomerFlags);
  const firstName = customer.get('first_name');
  const physicianTitle = customer.getIn(['physician', 'short_name']);
  const memberSince = useAppSelector((state) =>
    selectors.selectCustomerProduct(state, productCtx.selectedProduct),
  )?.get('member_since');

  const currentStatus = () => {
    const product = useAppSelector((state) => selectors.selectCustomerProduct(state, productCtx.selectedProduct));
    const status = product?.get('current_status');

    // NOTE: product should be displayed for customer as cancelled even if it's pending cancelation
    if (product?.get('cancelled_at')) return 'cancelled';
    if (status === 'ineligible') return 'pending';

    return status?.replace(/_/g, ' ');
  };

  const currentIntake = useAppSelector((state) => selectors.selectLastProductIntake(state, productCtx.selectedProduct));
  const erxShippedAt = currentIntake?.get('erx_shipped_at');
  const labOrderResults = useAppSelector((state) =>
    selectors.selectProductLabOrderResults(state, productCtx.selectedProduct),
  ).toJS();

  const clinicianReviewedAt = currentIntake?.get('clinician_reviewed_at');
  const clinicianQuestionsCompletedAt = currentIntake?.get('clinician_questions_completed_at');
  const patientPortalRegisteredAt = useAppSelector(selectors.selectCustomerQuestionnaire).get(
    'patient_portal_registered_at',
  );
  const isOnboarding = selectors.ONBOARDING_TYPES.includes(currentIntake?.get('type'));
  const referralSources = useAppSelector(selectors.selectCustomerReferralSources);

  const [showMobileProgressSteps, setShowMobileProgressSteps] = useState(false);
  const [currentPercentageImage, setCurrentPercentageImage] = useState(ProgressZero);
  const stepsBoolValues = [!!clinicianQuestionsCompletedAt, !!clinicianReviewedAt, !!erxShippedAt];
  if (isOnboarding) {
    stepsBoolValues.push(!!patientPortalRegisteredAt);
  }
  const percentagePassed = calculatePercentage(stepsBoolValues, isOnboarding);
  const isKing = productCtx?.selectedProduct === AvailableProducts.King;
  const isMagician = productCtx?.selectedProduct === AvailableProducts.Magician;
  const baselineLabResults =
    (isKing || isMagician) && baselineLabResultsState(clinicianQuestionsCompletedAt, labOrderResults);
  const portalRegistration = portalRegistrationState(clinicianQuestionsCompletedAt, patientPortalRegisteredAt);
  const showReferralSourcesModal = !!clinicianQuestionsCompletedAt && !referralSources;
  const showAdkDiscontinuationModal =
    isKing && !showReferralSourcesModal && flags.includes('notify_adk_discontinuation');
  const showBbRelaunchModal =
    isKing && !showReferralSourcesModal && !showAdkDiscontinuationModal && flags.includes('notify_bb_relaunch');

  useEffect(() => {
    setCurrentPercentageImage(imageMap[percentagePassed]);
  }, [percentagePassed]);

  /*
    DOCTOR REVIEW
    To-Do:
    King & Weight Loss onboarding - Once lab results uploaded
    Lover & Warrior onboarding - Once medical questionnaire is done
    All followup/sustains - once medical questionnaire is done
    Done: Prescription written (Elation data)
  */

  const prescription = currentIntake.get('prescriptions')?.first();

  const clinicianReviewStarted =
    isOnboarding && (isKing || isMagician)
      ? !!labOrderResults.length && !!clinicianQuestionsCompletedAt
      : !!clinicianQuestionsCompletedAt;
  const clinicianReviewCompleted = !!prescription;
  const clinicianReview = () => {
    let status = 'Not Started';
    let icon = IconLock;

    if (clinicianReviewCompleted) {
      /*
        dose_updated_at not available in new customer -> prescriptions, leave alone for now.
        maybe its just the created_at field for prescriptions, for future guys who fixes this if QA complains
      */
      status = `Completed on: ${formattedDate(prescription.get('dose_updated_at'))}`;
      icon = CirclePassed;
    } else if (clinicianReviewStarted) {
      status = 'In Progress';
      icon = CircleActive;
    }

    return { icon: icon, status: status };
  };

  /*
    ORDER FULFILLMENT
    To-do: Once prescription is written (Elation data) (regardless of how many fills have been shipped)
    Done: All prescription shipments have been logged
  */

  const allValidShipments: any[] = [];
  let totalOrdersWithRefills = 0;
  let prescriptions = customer.get('prescriptions')?.toJS() ?? [];
  prescriptions = prescriptions.filter(
    (prescription) =>
      prescription.product_name === productCtx.selectedProduct &&
      prescription.intake_name === currentIntake?.get('name'),
  );
  /*
    NOTE ON `totalOrdersWithRefills` FORMULA:
    Refills will be appended to  orders in prescriptions the future as they get fulfilled
    Every prescription SHOULD at the very least get its initial order fulfilled (even with no refills)
    which is what the +1 accounts for. (Basically the formula is accurate.)
  */
  prescriptions.forEach((prescription) => {
    totalOrdersWithRefills += (prescription?.refills ?? 0) + 1;
    const validOrders = prescription?.orders?.filter((order) => !order?.lost_or_returned_at) ?? [];
    allValidShipments.push(...validOrders);
  });
  const validShipmentsLength = allValidShipments.length;
  const remainingRefills = totalOrdersWithRefills - validShipmentsLength;

  const orderFulfillmentStarted = prescriptions.length > 0;
  const orderFulfillmentCompleted = prescriptions.length > 0 && remainingRefills === 0;
  const orderFulfillment = () => {
    let status = 'Not Started';
    let icon = IconLock;

    if (orderFulfillmentCompleted) {
      status = `Completed on: ${formattedDate(allValidShipments?.pop()?.date_shipped)}`;
      icon = CirclePassed;
    } else if (orderFulfillmentStarted) {
      status = 'In Progress';
      if (allValidShipments) {
        status += `, ${validShipmentsLength} Shipment${validShipmentsLength > 1 ? 's' : ''} sent`;
      }
      icon = CircleActive;
    }

    return { icon: icon, status: status };
  };

  /*
    RENEWAL QUESTIONNAIRE
    To-do: All prescription shipments have been logged
    Resolves: next Intake Assigned
  */

  const renewalQuestionnaireStarted = prescriptions.length > 0 && remainingRefills === 0;
  const renewalQuestionnaire = () => {
    let status = 'If Qualified';
    let icon = IconLock;

    if (renewalQuestionnaireStarted) {
      status = 'In Progress';
      icon = CircleActive;
    }

    return { icon: icon, status: status };
  };

  return (
    <div className="progress_tracker__header">
      {showReferralSourcesModal && <ReferralSourcesModal />}
      {showAdkDiscontinuationModal && <AdkDiscontinuationModal />}
      {showBbRelaunchModal && <BbRelaunchModal />}
      <div className="progress_tracker__content">
        <div className="progress_tracker__header__flex">
          <div className="progress_tracker__header__left">
            <TitleWithLine>Welcome Back</TitleWithLine>
            <h1 className="progress_tracker__header__name">{firstName}</h1>
            <p>Here’s what we have for you today.</p>
          </div>
          <div className="progress_tracker__header__right">
            <div className="flex">
              <div className="progress_tracker__header__right__item">
                <p className="progress_tracker__header__item_title">Protocol:</p>
                <p className="progress_tracker__header__item_value">
                  {protocolNames[productCtx?.selectedProductName?.toLowerCase() as AvailableProducts]}
                </p>
              </div>
              <div className="progress_tracker__header__right__item">
                <p className="progress_tracker__header__item_title">Doctor:</p>
                <p className="progress_tracker__header__item_value">{physicianTitle}</p>
              </div>
              <div className="progress_tracker__header__right__item">
                <p className="progress_tracker__header__item_title">Status:</p>
                <p className="progress_tracker__header__item_value">{currentStatus()}</p>
              </div>
              <div className="progress_tracker__header__right__item">
                <p className="progress_tracker__header__item_title">Member Since:</p>
                <p className="progress_tracker__header__item_value">{memberSince}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap50 hide-mobile">
          <div className="progress_tracker__header__doughnut">
            <ReactSVG src={currentPercentageImage} />
            <div className="progress_tracker__header__doughnut__value" />
          </div>
          <div className="progress_tracker__wizard">
            <WizardItem
              titleClassName={{ completed: !!clinicianQuestionsCompletedAt }}
              icon={clinicianQuestionsCompletedAt ? CirclePassed : CircleActive}
              status={clinicianQuestionsCompletedAt ? `Completed on: ${clinicianQuestionsCompletedAt}` : 'In Progress'}
            >
              Medical Questionnaire
            </WizardItem>

            {isOnboarding && (
              <>
                <WizardItem
                  className={cx({ not_started: !clinicianQuestionsCompletedAt })}
                  titleClassName={{ completed: !!patientPortalRegisteredAt }}
                  {...portalRegistration}
                >
                  Patient Passport Registration
                </WizardItem>
                {baselineLabResults && (
                  <WizardItem
                    className={{ not_started: !clinicianQuestionsCompletedAt }}
                    titleClassName={{ completed: !!labOrderResults.length }}
                    {...baselineLabResults}
                  >
                    Baseline Lab Results
                  </WizardItem>
                )}
              </>
            )}

            <WizardItem
              className={{ not_started: !clinicianReviewStarted }}
              titleClassName={{ completed: clinicianReviewCompleted }}
              {...clinicianReview()}
            >
              Doctor Review
            </WizardItem>

            <WizardItem
              className={{ not_started: !orderFulfillmentStarted }}
              titleClassName={{ completed: orderFulfillmentCompleted }}
              {...orderFulfillment()}
            >
              Order Fulfillment
            </WizardItem>

            <WizardItem
              className={{ not_started: !renewalQuestionnaireStarted }}
              {...renewalQuestionnaire()}
              withoutLine
            >
              Renewal Questionnaire
            </WizardItem>
          </div>
        </div>
        <div className="progress_tracker__header__steps_button">
          <Button
            className={showMobileProgressSteps ? 'primary-button' : 'outlined-button'}
            onClick={() => setShowMobileProgressSteps(!showMobileProgressSteps)}
            text={
              <>
                <ReactSVG src={IconHamburger} />
                {`${showMobileProgressSteps ? 'CLOSE ' : ''}TREATMENT STEPS`}
              </>
            }
          />
        </div>
        {showMobileProgressSteps && (
          <div>
            <div className="progress_tracker__header__modal">
              <div className="progress_tracker__header__doughnut">
                <ReactSVG src={currentPercentageImage} />
                <div className="progress_tracker__header__doughnut__value" />
              </div>
              <TreatmentItem
                titleClassName={{ completed: !!clinicianQuestionsCompletedAt }}
                status={
                  clinicianQuestionsCompletedAt ? `Completed on: ${clinicianQuestionsCompletedAt}` : 'In-Progress'
                }
                icon={clinicianQuestionsCompletedAt ? CirclePassed : CircleActive}
              >
                Medical Questionnaire
              </TreatmentItem>

              {isOnboarding && (
                <>
                  <TreatmentItem titleClassName={{ completed: !!patientPortalRegisteredAt }} {...portalRegistration}>
                    Patient Passport Registration
                  </TreatmentItem>
                  {baselineLabResults && (
                    <TreatmentItem titleClassName={{ completed: !!labOrderResults.length }} {...baselineLabResults}>
                      Baseline Lab Results
                    </TreatmentItem>
                  )}
                </>
              )}
              <TreatmentItem titleClassName={{ completed: !!clinicianReviewCompleted }} {...clinicianReview()}>
                Doctor Review
              </TreatmentItem>

              <TreatmentItem titleClassName={{ completed: orderFulfillmentCompleted }} {...orderFulfillment()}>
                Order Fulfillment
              </TreatmentItem>

              <TreatmentItem withoutLine {...renewalQuestionnaire()}>
                Renewal Questionnaire
              </TreatmentItem>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProgressTrackerHeader;
