import * as Routes from 'app/constants/Routes';
import { push } from 'connected-react-router/immutable';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'app/actions/customer';
import * as selectors from 'app/selectors/customer';

const mapStateToProps = (state) => ({
  intake: selectors.selectCurrentIntake(state),
});

const WarriorDummy = ({ step, intake, dispatch }) => {
  useEffect(() => {
    dispatch(
      actions.apiRequestUserCommand({
        cmdType: 'answer_questions',
        params: { [step]: true, intake: intake.get('name') },
        context: {
          onSuccessActionCreator: ({ json: { entity } }) =>
            push(
              Routes.createIntake(
                intake.get('name'),
                entity.maximus_products.warrior.intakes[0].steps.filter((step) => !step.completed)[0].url_friendly_name,
              ),
            ),
        },
      }),
    );
  });

  return null;
};

export default connect(mapStateToProps)(WarriorDummy);
