import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { apiRequestCommand } from 'app/actions/customer';
import {
  ONBOARDING_TYPES,
  selectAllErxShipmentDatesV2,
  selectCustomer,
  selectCustomerFlags,
  selectCustomerId,
  selectCustomerLabOrders,
  selectCustomerProductNames,
  selectCustomerProducts,
  selectCustomerQuestionnaire,
  selectProductLabOrderResults,
} from 'app/selectors/customer';
import { startCase } from 'lodash';
import { List, Map } from 'immutable';
import { useAppDispatch, useAppSelector, useQuery } from 'app/helpers/hooks';
import Spinner from '../Spinner';
import { ProductContext } from './context';
import { collectNotifications } from '../ProgressTracker/ProgressTrackerUpNext';
import { AvailableProducts } from 'app/constants/Products';
import { BaseKingIntake, CustomerUserImm, MaximusProduct } from 'app/types/admin/customerUser';
import { ImmutableMapping } from 'app/types/admin';

export const isProductHasPendingActions = (
  customer: CustomerUserImm,
  p: ImmutableMapping<MaximusProduct>,
  pName: string,
) => {
  const lastIntake = p.get('intakes').last() as ImmutableMapping<BaseKingIntake>;
  if (!lastIntake || !lastIntake.get('completed')) {
    return true;
  }
  const state = {
    customer: Map({ customer }),
  };
  const patientPortalRegisteredAt = selectCustomerQuestionnaire(state).get('patient_portal_registered_at');
  if (!patientPortalRegisteredAt) {
    return true;
  }

  const dismissedNotifications = customer.get('dismissed_notifications', List());
  const isDismissed = (name) => dismissedNotifications.some((n) => n.get('notification_name') === name);

  const labInfo = selectCustomerLabOrders(state)
    ?.toJS()
    ?.find((order) => order.intake_name === lastIntake?.get('name')) || { shipped_at: null };
  const labOrderResults = selectProductLabOrderResults(state as never, pName);
  const erxShipmentDates = selectAllErxShipmentDatesV2(state) || [];

  const notifications = collectNotifications(isDismissed, lastIntake, labInfo, labOrderResults, erxShipmentDates);
  const newNotificationsCount = notifications.filter((a) => !a.dismissed).length;
  if (newNotificationsCount) {
    return true;
  }

  if (pName === 'king') {
    if (ONBOARDING_TYPES.includes(lastIntake.get('type')) && !p.get('lab_order_results')?.size) {
      return true;
    }
  }

  return false;
};

export const ProductProvider: FC = ({ children }) => {
  const query = useQuery();

  const customer = useAppSelector(selectCustomer);
  const customerFlags = useAppSelector(selectCustomerFlags);
  const allProducts = useAppSelector(selectCustomerProducts);

  /** Name of product which should be shown by default */
  let defaultProduct: string = query.get('product') || '';

  if (!allProducts.keySeq().includes(defaultProduct as AvailableProducts)) {
    defaultProduct = '';
  }
  if (!defaultProduct) {
    // Not cancelled products
    const activeProducts = allProducts.filter(
      (p) => !!(p && !(p as unknown as ImmutableMapping<MaximusProduct> | undefined)?.get('cancelled')),
    );

    if (activeProducts.size > 0) {
      if (activeProducts.size === 1) {
        defaultProduct = activeProducts.keySeq().first() as string;
      } else {
        const productsWithPendingActions = activeProducts
          .filter((p, pName) => isProductHasPendingActions(customer, p as any, pName))
          .keySeq()
          .toList();

        defaultProduct = (productsWithPendingActions.first() || activeProducts.keySeq().first()) as string;
      }
    } else {
      // Pending products don't have a value
      const pendingProducts = allProducts
        .filter((v) => !v)
        .keySeq()
        .toArray()
        .filter((productName) => customerFlags.includes(`create_${productName}_product_available`));

      if (pendingProducts.length === 1) {
        defaultProduct = pendingProducts[0] as string;
      } else {
        defaultProduct = allProducts
          .filter((p) => !!p)
          .keySeq()
          .toArray()[0] as string;
      }
    }
  }
  const [selectedProduct, setSelectedProduct] = useState<AvailableProducts | null>(
    (defaultProduct as AvailableProducts) || null,
  );

  const [isPending, setIsPending] = useState(true);
  const change = useCallback((product) => {
    setSelectedProduct(product);
  }, []);
  const userProducts = useAppSelector(selectCustomerProductNames);
  const dispatch = useAppDispatch();
  const customerId = useAppSelector(selectCustomerId);
  useEffect(() => {
    if (!userProducts?.includes(selectedProduct!)) {
      if (selectedProduct) {
        const action = apiRequestCommand({
          params: {
            type: 'create_product',
            user_id: customerId,
            params: {
              product_name: selectedProduct,
            },
          },
          context: {},
        });
        (dispatch(action) as unknown as Promise<any>).finally(() => setIsPending(false));
      }
    } else {
      setIsPending(false);
    }
  }, [customerId, userProducts, selectedProduct]);

  const ctx = useMemo(
    () => ({
      selectedProduct,
      selectedProductName: selectedProduct && startCase(selectedProduct),
      change,
    }),
    [change, selectedProduct],
  );

  return <ProductContext.Provider value={ctx}>{isPending ? <Spinner isCenter /> : children}</ProductContext.Provider>;
};
