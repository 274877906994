/* eslint-disable no-case-declarations */
import BasePriceCalculator from 'app/helpers/productSpecificPriceCalculators/basePriceCalculator';
import { WarriorSubProducts } from 'app/constants/Products';

const BB_PRICING = {
  3: 44_99,
};

export const WARRIOR_MONTHLY_PRICE = {
  [WarriorSubProducts.OralMinoxidil]: {
    3: 24_99,
  },
  [WarriorSubProducts.OralDutasteride]: {
    3: 34_99,
  },
  [WarriorSubProducts.OralFinasteride]: {
    3: 34_99,
  },
  [WarriorSubProducts.TopicalMinoxidil]: {
    3: 44_99,
  },
  [WarriorSubProducts.TopicalDutasteride]: {
    3: 54_99,
  },
  [WarriorSubProducts.TopicalFourInOne]: {
    3: 64_99,
  },
  [WarriorSubProducts.OralMinoxidilTopicalDutasteride]: {
    3: 79_99,
  },
  [WarriorSubProducts.OralDutasterideTopicalMinoxidil]: {
    3: 79_99,
  },
  [WarriorSubProducts.OralDutasterideOralMinoxidil]: {
    3: 59_99,
  },
};

const WARRIOR_DEFAULT_MULTIMONTH_PLAN = 3;

class WarriorPriceCalculator extends BasePriceCalculator {
  warrior_strength: WarriorSubProducts;
  includeDermastamp: boolean;

  constructor(
    includeBB: boolean,
    includeBBonAnotherProtocol: boolean,
    intakeName: string,
    warrior_strength: WarriorSubProducts,
    includeDermastamp: boolean,
  ) {
    super(includeBB, includeBBonAnotherProtocol, intakeName, WARRIOR_DEFAULT_MULTIMONTH_PLAN);
    this.warrior_strength = warrior_strength;
    this.includeDermastamp = includeDermastamp;
  }

  monthlyCost() {
    return WARRIOR_MONTHLY_PRICE[this.product()][this.multiMonthPlan];
  }

  product = () => this.warrior_strength;

  bbPrice = () => {
    return BB_PRICING[this.multiMonthPlan];
  };

  dermastampPrice = () => 15_00;

  subscriptionCost() {
    if (this.subscriptionPaid) return 0;

    const bbMonthlyPrice = this.includeBB ? BB_PRICING[this.multiMonthPlan] : 0;
    return (WARRIOR_MONTHLY_PRICE[this.product()][this.multiMonthPlan] + bbMonthlyPrice) * this.multiMonthPlan;
  }

  totalDueTodayForCurrentProduct() {
    if (!this.intakeName.includes('onboarding')) {
      return 0;
    }
    const dermastampPrice = this.includeDermastamp ? this.dermastampPrice() : 0;
    return this.subscriptionCost() + dermastampPrice;
  }
}

export default WarriorPriceCalculator;
