import * as sessionReplay from '@amplitude/session-replay-browser';
import { AnalyticsBrowser } from '@segment/analytics-next';
import Cookies from 'js-cookie';

const AMPLITUDE_API_KEY = window.amplitudeAPIKey;
const SEGMENT_WRITE_KEY = window.segmentJsWriteKey;

const getStoredSessionId = () => {
  const sessionId = Cookies.get('analytics_session_id') || 0;
  return sessionId ? parseInt(sessionId, 10) : 0;
};

export const initializeAmplitudeSessionReplay = async () => {
  const segmentAnalytics = AnalyticsBrowser.load({
    writeKey: SEGMENT_WRITE_KEY,
  });

  const user = await segmentAnalytics.user();
  const storedSessionId = getStoredSessionId();
  try {
    await sessionReplay.init(AMPLITUDE_API_KEY, {
      sessionId: storedSessionId,
      deviceId: String(user.anonymousId()),
      sampleRate: window.amplitudeSessionReplaySampleRate,
      debugMode: true,
    }).promise;
  } catch (error) {
    console.error('Error initializing Session Replay:', error);
  }

  segmentAnalytics.ready(() => {
    segmentAnalytics.addSourceMiddleware(({ payload, next }) => {
      const storedSessionId = getStoredSessionId();
      const amplitudeIntegration = payload.obj?.integrations?.['Actions Amplitude'];
      const nextSessionId =
        typeof amplitudeIntegration === 'object' && 'session_id' in amplitudeIntegration
          ? Number(amplitudeIntegration.session_id)
          : 0;

      if (storedSessionId < nextSessionId) {
        Cookies.set('analytics_session_id', String(nextSessionId));
        sessionReplay.setSessionId(nextSessionId);
      }
      next(payload);
    });

    segmentAnalytics.addSourceMiddleware(({ payload, next }) => {
      const sessionReplayProperties = sessionReplay.getSessionReplayProperties();
      if (payload.type() === 'track') {
        payload.obj.properties = {
          ...payload.obj.properties,
          ...sessionReplayProperties,
        };
      }
      next(payload);
    });

    segmentAnalytics.track('SessionReplayInitialize', {});

    // Handle Intercom behavior
    triggerIntercomSpace();
  });
};

// Function to handle Intercom behavior (hide on load, show after 30s or scroll)
const triggerIntercomSpace = () => {
  const pollForIntercom = setInterval(() => {
    if (window.Intercom) {
      clearInterval(pollForIntercom); // Stop polling once Intercom is available

      let hasTriggered = false;

      // Trigger after 30 seconds
      const timer = setTimeout(() => {
        if (!hasTriggered) {
          hasTriggered = true;
          window.Intercom('showSpace', 'home');
        }
      }, 30000);

      // Trigger on 50% scroll
      const scrollListener = () => {
        const scrollPosition = window.scrollY + window.innerHeight;
        const pageHeight = document.body.scrollHeight;

        if (scrollPosition >= pageHeight / 2 && !hasTriggered) {
          hasTriggered = true;
          window.Intercom('showSpace', 'home');

          // Cleanup timer and scroll listener
          clearTimeout(timer);
          window.removeEventListener('scroll', scrollListener);
        }
      };
      window.addEventListener('scroll', scrollListener);
    }
  }, 500); // Poll every 500ms
};
