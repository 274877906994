import React from 'react';

const DiscountBanner = () => {
  return (
    <div className="discount-banner-exp">
      <p>
        <span>New Years Sale</span> on now — Save up to <span>$360</span> on any testosterone plan.
      </p>
    </div>
  );
};

export default DiscountBanner;
