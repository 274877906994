import { apiRequestUserCommand } from 'app/actions/customer';

import HeightQuestion from './Height';
import MultiSelectQuestion from './MultiSelect';
import NumericQuestion from './Numeric';
import TextAreaQuestion from './TextArea';
import Radio from './Radio';
import SwitchableRadio from './SwitchableRadio';
import BodyfatQuestion from './Bodyfat';
import { ConditionsAndMedicationsQuestion, RefillConditionsQuestion } from '../steps/ConditionsAndMedications';

export const getComponentForQuestion = ({ question }) => {
  const { type } = question;

  switch (type) {
    case 'vertical_options':
      return Radio;
    case 'switchable_vertical_options':
      return SwitchableRadio;
    case 'rank_order':
    case 'multi_select':
      return MultiSelectQuestion;
    case 'text_area':
      return TextAreaQuestion;
    case 'height':
      return HeightQuestion;
    case 'bodyfat':
      return BodyfatQuestion;
    case 'numeric':
      return NumericQuestion;
    case 'conditions_and_medications':
      return ConditionsAndMedicationsQuestion;
    case 'refill_conditions':
      return RefillConditionsQuestion;
    default:
      return null;
  }
};

export const getDefaultValue = ({
  initial,
  question,
}: {
  question:
    | {
        type: 'numeric';
      }
    | {
        type: 'multi_select';
        data: {
          none_of_the_above?: {
            text: string;
          };
          no_other?: boolean;
        };
        options: {
          value: string;
          label?: string;
        }[];
      }
    | {
        type: 'string' | 'boolean';
      };
  initial?: any;
}) => {
  const { type } = question;
  if (type === 'numeric') {
    return initial || '0';
  }
  if (type === 'multi_select') {
    const initialObject = initial || {};
    const { none_of_the_above = false, other = false, other_content = '' } = initialObject;
    const init: Record<string, boolean | string> = {};
    const noneOfTheAboveText = question.data.none_of_the_above?.text;
    if (noneOfTheAboveText) {
      init.none_of_the_above = none_of_the_above;
    }
    if (!question.data.no_other) {
      init.other = other;
      init.other_content = other_content;
    }
    const { options } = question;

    const result = options.reduce(
      (acc, option) => ({
        ...acc,
        [option.value]: initialObject[option.value] ?? false,
      }),
      init,
    );

    return result;
  }

  return initial ?? '';
};

export const getOnSubmit = ({
  intake,
  onSuccessAction = undefined,
  onSuccessActionCreator = undefined,
  question,
  dispatch,
  trackCompletionEvent,
}) => {
  const onSubmit = (values) => {
    const { name } = question;
    const cmdType = 'answer_questions';
    let normalizedValue = (values[name] === '' ? null : values[name]) ?? null;
    if (normalizedValue === null && question.type === 'text_area') {
      normalizedValue = '';
    }
    const params: Record<string, any> = { [name]: normalizedValue };
    if (intake) {
      params.intake = intake.get('name');
    }
    const action = apiRequestUserCommand({
      cmdType,
      params,
      context: { onSuccessAction, onSuccessActionCreator, form: name },
    });
    // Track Segment completion event with the answer
    trackCompletionEvent?.({ answer: normalizedValue });

    dispatch(action);
  };

  return onSubmit;
};

/**
 * Method to scroll into view port, if it's outside the viewport
 */
export const scrollIntoViewIfNeeded = (target: HTMLElement, offset = { fromTop: 0, fromBottom: 0 }) => {
  let { top, bottom } = target.getBoundingClientRect();
  top -= offset.fromTop;
  bottom += offset.fromBottom;

  if (top < 0 || bottom > window.innerHeight) {
    target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};
