import React from 'react';
import { Formik, Form } from 'formik';
import Button from 'app/components/common/formik/Button';
import Input from 'app/components/common/formik/Input';
import { RegistrationSchemaStep3 } from 'app/helpers/validators';
import { useSegmentTracking } from 'app/utils/useSegmentTracking';
import { SEGMENT_KING_EVENTS } from 'app/constants/SegmentEvents';

const NameStep = ({ initialValues, onSubmit, trackSegmentEvent = false }) => {
  useSegmentTracking(trackSegmentEvent ? SEGMENT_KING_EVENTS.VIEWED_NAME_ENTRY : undefined);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={RegistrationSchemaStep3}>
      {({ isSubmitting }) => (
        <Form className="registration-flow registration-flow_step-1">
          <h2 className="title">Please introduce yourself.</h2>
          <p className="description">
            Your name will be used by our doctors and our pharmacy to deliver your optimized treatment protocol (if
            qualified).
          </p>

          <div className="inputs">
            <div className="inputs__item">
              <Input id="first_name" name="first_name" placeholder="First Name" className="text-field" />
            </div>

            <div className="inputs__item">
              <Input id="last_name" name="last_name" placeholder="Last Name" className="text-field" />
            </div>
          </div>

          <Button
            disabled={isSubmitting}
            style="primary"
            type="submit"
            data-testid="registration-step-3-submit"
            text="Next"
            className="button-next"
          />
        </Form>
      )}
    </Formik>
  );
};

export default NameStep;
