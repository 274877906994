export const isDev = (): boolean => ['localhost'].includes(window.location.hostname);

export const isStagingOrLower = (): boolean =>
  ['preview', 'development', 'dev_anon', 'staging', 'personal', 'test'].includes(window.currentEnvironment || '');

export const isProduction = (): boolean =>
  ['app.bemaximus.com', 'app.maximustribe.com'].includes(window.location.hostname);

export const isTestOrLower = (): boolean =>
  ['development', 'test', 'personal'].includes(window.currentEnvironment || '');

export const withPersonaTemplateId = (): string =>
  isProduction() ? `itmpl_mScNabR9FfEvRTN8uyMPTstn` : `itmpl_rHvBm3L8gfAmuFk31Q8EQtF1`;

export const withPersonaEnv = (): string =>
  isProduction() ? `env_MAm3aAoQ2xNZkczK6uPmMUAv` : `env_A73JzQD3Nc1sjKU64HzZ2ZRp`;

export const withPersonaUrl = (inquiryId: string): string => {
  const prefix = `https://app.withpersona.com/dashboard/inquiries/${inquiryId}`;
  return prefix;
};

export const elationUrl = (elationId: string): string =>
  isProduction()
    ? `https://app.elationemr.com/patient/${elationId}`
    : `https://sandbox.elationemr.com/patient/${elationId}`;

export const stripeTransactionUrl = (paymentId: string): string =>
  isProduction()
    ? `https://dashboard.stripe.com/payments/${paymentId}`
    : `https://dashboard.stripe.com/test/payments/${paymentId}`;

export const stripeUrl = (stripeId: string): string =>
  isProduction()
    ? `https://dashboard.stripe.com/customers/${stripeId}`
    : `https://dashboard.stripe.com/test/customers/${stripeId}`;

export const stripeQueryUrl = (queryId: string): string =>
  isProduction()
    ? `https://dashboard.stripe.com/search?query=${queryId}`
    : `https://dashboard.stripe.com/test/search?query=${queryId}`;
