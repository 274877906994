import React from 'react';
import { Link } from 'react-router-dom';
import * as Routes from 'app/constants/Routes';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import Button from 'app/components/common/formik/Button';
import Input from 'app/components/common/formik/Input';
import { RegistrationSchemaStep4 } from 'app/helpers/validators';
import { phoneDisplayFormatter } from 'app/helpers/formatters';
import { useSegmentTracking } from 'app/utils/useSegmentTracking';
import { SEGMENT_KING_EVENTS } from 'app/constants/SegmentEvents';

const AccountRegistrationStep = ({ initialValues, emailExist, onSubmit, trackSegmentEvent = false }) => {
  useSegmentTracking(trackSegmentEvent ? SEGMENT_KING_EVENTS.VIEWED_ACCOUNT_CREATION : undefined);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={RegistrationSchemaStep4}>
      {({ isSubmitting, errors, setFieldValue }) => (
        <Form className="registration-flow registration-flow_step-2">
          <h2 className="title">Next we need to set up an account for you.</h2>
          <p className="description">
            The email you provide will be used as your username for future access to the Maximus portal.
          </p>

          <div className="inputs">
            <div className="inputs__item">
              <Input
                onChange={(e) => setFieldValue('email', e.target.value.trim())}
                id="email"
                name="email"
                placeholder="Email"
                className="text-field"
                disabled={emailExist}
              />
              {emailExist && (
                <div className="exist">
                  This email already exists. Would you like to <Link to={Routes.Login}>Log in</Link>?
                </div>
              )}
            </div>
            <div
              className={cx('inputs__item', {
                inputs__item_hidden: emailExist,
              })}
            >
              <Input
                id="phone_number"
                name="phone_number"
                placeholder="Phone #"
                type="tel"
                onChange={(e) => setFieldValue('phone_number', phoneDisplayFormatter(e.target.value))}
                className="text-field text-field__phone_number"
                disabled={emailExist}
                insideElement={<span className="text-field__phone_number__label">(optional)</span>}
              />
              <br />
            </div>
            <div
              className={cx('inputs__item', {
                inputs__item_hidden: emailExist,
              })}
            >
              <Input
                id="password"
                name="password"
                placeholder="Password"
                type="password"
                className="text-field"
                disabled={emailExist}
              />
            </div>
            <div
              className={cx('inputs__item', {
                inputs__item_hidden: emailExist,
              })}
            >
              <Input
                id="password_confirmation"
                name="password_confirmation"
                type="password"
                placeholder="Confirm Password"
                className="text-field"
                disabled={emailExist}
              />
            </div>
          </div>

          {errors && errors.api && <div className="errors">{errors.api}</div>}

          {emailExist ? (
            <Link className="button-next default-button primary-button link-as-button" to={Routes.Login}>
              Log In
            </Link>
          ) : (
            <>
              <Button
                disabled={isSubmitting}
                style="primary"
                type="submit"
                data-testid="registration-step-4-submit"
                text="Next"
                className="button-next"
              />

              <div className="links">
                <div className="links__item">
                  Already have an account? <Link to={Routes.Login}>Log In</Link>
                </div>
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AccountRegistrationStep;
