import React from 'react';
import { Formik, Form } from 'formik';
import FormField from 'app/components/common/NewDesignSystem/FormField';
import Input from 'app/components/common/formik/Input';
import { IdentitySchema } from 'app/helpers/validators';
import Select from 'react-select';
import { apiRequestUserCommand } from 'app/actions/customer';
import { useDispatch } from 'react-redux';

const SEX_OPTIONS = [
  { value: 'f', label: 'Female' },
  { value: 'm', label: 'Male' },
];

const PersonalDetailForm = ({
  personalFormRef,
  setPersonalFormValid,
  titleClasses = 'title_with_line xl mt48 mv16',
}) => {
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    const action = apiRequestUserCommand({
      cmdType: 'answer_questions',
      params: values,
      context: {},
    });
    dispatch(action);
  };

  return (
    <>
      <h2 className={`${titleClasses} animated-deep-hidden`}>Personal Details</h2>
      <Formik
        innerRef={personalFormRef}
        initialValues={{ date_of_birth: null, sex: null }}
        onSubmit={onSubmit}
        validationSchema={IdentitySchema}
      >
        {({ values, setFieldValue, isValid }) => {
          setPersonalFormValid(isValid);
          return (
            <Form>
              <div className="row">
                <FormField label={'Date of Birth'} className="col-12 col-sm-6">
                  <Input name="date_of_birth" type="date" />
                </FormField>
                <FormField label={'Sex at Birth'} className="col-12 col-sm-6">
                  <Select
                    classNamePrefix="select"
                    options={SEX_OPTIONS}
                    value={SEX_OPTIONS.find((p) => p.value === values.sex)}
                    isSearchable={false}
                    onChange={(v) => setFieldValue('sex', v?.value)}
                  />
                </FormField>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default PersonalDetailForm;
