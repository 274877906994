import { useEffect } from 'react';

export const useSegmentTracking = (viewEventName?: string, completionEventName?: string) => {
  useEffect(() => {
    if (window.analytics && viewEventName) {
      window.analytics.page(viewEventName, 'viewEventName', { url: window.location.href });
    }
  }, [viewEventName]);

  const trackCompletionEvent = (properties) => {
    if (window.analytics && completionEventName) {
      window.analytics.page(completionEventName, 'completionEventName', { url: window.location.href, ...properties });
    }
  };

  return { trackCompletionEvent };
};
